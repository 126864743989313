.global {
    height: 100vh;
    width: 100vw;
    background: linear-gradient(to bottom, #1a1b1e, #121315, #1a1b1e);
    font-family: 'Trebuchet MS', sans-serif;
    overflow: auto;
    color: #66b3ff;
    font-size: 1rem;
    border-color: #3399ff; 
    font-weight: 400;
  }